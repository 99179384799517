import React, { FC } from 'react';

import { classes } from './Badge.st.css';

interface BadgeProps {
  backgroundColor?: string;
  icon?: string;
  title?: string;
}

export const Badge: FC<BadgeProps> = ({ backgroundColor, icon, title }) => {
  const style = {
    ...(backgroundColor && { backgroundColor }),
  };

  return (
    <div className={classes.root} style={style}>
      {icon && <img className={classes.icon} src={icon} alt={title} />}
    </div>
  );
};
