import { blockedMemberId } from '../../../../constants';
import { useAppSettings, useMember } from '../../../../contexts/widgets';
import {
  ControllerProps,
  SettingsSection,
  SettingsSectionState,
  SettingsTab,
} from '../../../../types';

interface UseEmptyStateRendererOptions {
  shouldRenderDescription: boolean;
  shouldRenderBadges: boolean;
  shouldRenderOverview: boolean;
  shouldRenderBlogPosts: boolean;
}

const shouldForceEmptyState = ({
  tabOpened,
  sectionOpened,
  sectionState,
}: ControllerProps['appSettings']) => {
  if (sectionState !== SettingsSectionState.Empty) {
    return false;
  }

  return (
    (tabOpened === SettingsTab.Sections &&
      sectionOpened === SettingsSection.About) ||
    (tabOpened === SettingsTab.Design &&
      sectionOpened === SettingsSection.TextStyle)
  );
};

export const useEmptyStateRenderer = ({
  shouldRenderDescription,
  shouldRenderBadges,
  shouldRenderOverview,
  shouldRenderBlogPosts,
}: UseEmptyStateRendererOptions) => {
  const appSettings = useAppSettings();

  if (shouldForceEmptyState(appSettings)) {
    return { shouldRenderEmptyState: true };
  }

  return {
    shouldRenderEmptyState:
      !shouldRenderDescription &&
      !shouldRenderBadges &&
      !shouldRenderOverview &&
      !shouldRenderBlogPosts,
  };
};

export const useBlockedEmptyStateRenderer = () => {
  const { id } = useMember();
  return { shouldRenderBlockedEmptyState: id === blockedMemberId };
};
