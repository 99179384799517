import React, { FC } from 'react';
import { Divider } from 'wix-ui-tpa';

import { Field as IField, FieldType } from '../../../../types';
import { useCustomProfile } from './hooks';
import EmptyState from './EmptyState';
import Title from './Title';
import Field from './Field';
import SocialLink from './SocialLink';
import { classes, st } from './Overview.st.css';

const getFieldStates = (field: IField) => ({
  link: field.type === FieldType.URL,
});

export const Overview: FC = () => {
  const customProfile = useCustomProfile();
  const { fields, socialLinks } = customProfile;

  if (!fields.length && !socialLinks.length) {
    return (
      <>
        <Title customProfile={customProfile} />
        <EmptyState />
      </>
    );
  }

  return (
    <>
      <Title customProfile={customProfile} />
      {fields.length > 0 && (
        <div>
          {fields.map((field) => (
            <div
              key={field.id}
              className={st(classes.item, getFieldStates(field))}
            >
              <Field {...field} />
            </div>
          ))}
        </div>
      )}
      {fields.length > 0 && socialLinks.length > 0 && (
        <Divider className={classes.divider} />
      )}
      {socialLinks.length > 0 && (
        <div>
          {socialLinks.map((socialLink) => (
            <div
              key={socialLink.id}
              className={st(classes.item, { link: true })}
            >
              <SocialLink {...socialLink} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
