import React, { FC } from 'react';

interface GifSmallProps {
  className: string;
}

const GifSmall: FC<GifSmallProps> = ({ className }) => (
  <svg className={className} width="19" height="19" viewBox="0 0 19 19">
    <g fill="none" fillRule="evenodd">
      <g fill="#000">
        <path
          d="M18.002 3v1H14v4.027h2.85v1H14v7.1h-1V3h5.002zM10 3.028v13.066H9V3.028h1zm-6.78 0C4.754 3.028 6 4.363 6 6.009h-.922c0-1.1-.832-1.992-1.859-1.992H3c-1.148 0-2.078.997-2.078 2.228v6.566c0 1.23.93 2.228 2.078 2.228 1.148 0 2.078-.998 2.078-2.228v-1.843c0-.273-.207-.495-.461-.495H2.772v-.989h1.845c.764 0 1.383.664 1.383 1.484v1.843c0 1.777-1.343 3.217-3 3.217s-3-1.44-3-3.217V6.245c0-1.777 1.343-3.217 3-3.217z"
          transform="translate(-896 -606) translate(803 485) translate(30 52) translate(20 69) translate(43)"
        />
      </g>
    </g>
  </svg>
);

export default GifSmall;
