import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { classes, st } from './SectionTitle.st.css';

export const SectionTitle: FC = ({ children }) => {
  const { isMobile } = useEnvironment();
  return (
    <Text className={st(classes.root, { mobile: isMobile })} tagName="h6">
      {children}
    </Text>
  );
};
