import React, { FC, useEffect } from 'react';

import { Toast } from '../../components/Profile/Widget/common/Toast';
import { useUI, useUIHandlers } from '../widgets';

const toastFadeOutDelay = 5000;

// TODO: Wrap Toast with Loadable afterwards.
export const ToastContextProvider: FC = ({ children }) => {
  const { toast } = useUI();
  const { hideToast } = useUIHandlers();

  useEffect(() => {
    if (toast.isVisible) {
      const timeout = setTimeout(hideToast, toastFadeOutDelay);
      return () => clearTimeout(timeout);
    }
  }, [toast.isVisible, hideToast]);

  return (
    <>
      <Toast />
      {children}
    </>
  );
};
