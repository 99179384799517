import React, { FC } from 'react';
import { TPAComponentsProvider } from 'wix-ui-tpa';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';

import { ControllerProps } from '../../../types';
import { WidgetContextProvider } from '../../../contexts/widgets';
import { ModalsContextProvider } from '../../../contexts/modals';
import { ToastContextProvider } from '../../../contexts/toast';
import { Widget } from './Widget';

const Root: FC<WidgetProps<ControllerProps | undefined>> = (props) => {
  const { isMobile, isRTL } = useEnvironment();

  if (!props.member?.id) {
    return null;
  }

  return (
    <WidgetContextProvider {...props}>
      <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
        <ModalsContextProvider>
          <ToastContextProvider>
            <Widget {...props} />
          </ToastContextProvider>
        </ModalsContextProvider>
      </TPAComponentsProvider>
    </WidgetContextProvider>
  );
};

export default Root;
