import React, { FC } from 'react';

interface VideoSmallProps {
  className: string;
}

const VideoSmall: FC<VideoSmallProps> = ({ className }) => (
  <svg className={className} width="19" height="19" viewBox="0 0 19 19">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-939 -606) translate(803 485) translate(30 52) translate(20 69) translate(86)">
        <path
          fill="#333"
          fillRule="nonzero"
          d="M13 4c.552 0 1 .448 1 1v1.999l2.842-1.42c.395-.198.876-.038 1.074.358.055.11.084.233.084.357v6.412c0 .441-.358.8-.8.8-.124 0-.247-.03-.358-.085L14 12v2c0 .552-.448 1-1 1H2c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1h11zm0 1H2v9h11V5zm3.977 1.833c-.05-.099-.17-.139-.269-.09L14 8.098V10.9l2.708 1.354c.014.007.029.012.044.016l.046.005c.11 0 .2-.09.2-.2V6.922c0-.03-.007-.061-.021-.089z"
        />
      </g>
    </g>
  </svg>
);

export default VideoSmall;
