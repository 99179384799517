import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { classes, st } from './EmptyState.st.css';

export const EmptyState: FC = () => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  return (
    <Text className={st(classes.root, { mobile: isMobile })}>
      {t('about.widget.overview-empty-state')}
    </Text>
  );
};
