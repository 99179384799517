import {
  StyleParamType,
  createStylesParams,
  wixFontParam,
  wixColorParam,
} from '@wix/tpa-settings';

export type StylesParams = {
  wrapperBackgroundColor: StyleParamType.Color;
  wrapperBorderColor: StyleParamType.Color;
  wrapperBorderWidth: StyleParamType.Number;
  spaceFromTop: StyleParamType.Number;
  spaceOverviewInformation: StyleParamType.Number;
  spaceBetweenSections: StyleParamType.Number;
  titleFont: StyleParamType.Font;
  titleColor: StyleParamType.Color;
  sectionTitleFont: StyleParamType.Font;
  sectionTitleFontSize: StyleParamType.Number;
  sectionTitleColor: StyleParamType.Color;
  sectionDescriptionFont: StyleParamType.Font;
  sectionDescriptionFontSize: StyleParamType.Number;
  sectionDescriptionColor: StyleParamType.Color;
  buttonFont: StyleParamType.Font;
  buttonFontSize: StyleParamType.Number;
  buttonBgColor: StyleParamType.Color;
  textPrimaryColor: StyleParamType.Color;
  dividerColor: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  fieldLabelFont: StyleParamType.Font;
  fieldLabelFontSize: StyleParamType.Number;
  fieldLabelColor: StyleParamType.Color;
  fieldPlaceholderFont: StyleParamType.Font;
  fieldPlaceholderFontSize: StyleParamType.Number;
  fieldPlaceholderColor: StyleParamType.Color;
  fieldTextFont: StyleParamType.Font;
  fieldTextFontSize: StyleParamType.Number;
  fieldTextColor: StyleParamType.Color;
  badgeTitleFont: StyleParamType.Font;
  badgeTitleFontSize: StyleParamType.Number;
  badgeTitleColor: StyleParamType.Color;
  badgeFont: StyleParamType.Font;
  badgeFontSize: StyleParamType.Number;
  badgeColor: StyleParamType.Color;
  blogPostCardWidth: StyleParamType.Number;
  blogPostCardHeight: StyleParamType.Number;
  blogPostCardMargin: StyleParamType.Number;
  blogPostCardBackgroundColor: StyleParamType.Color;
  blogPostCardBorderColor: StyleParamType.Color;
  blogPostCardBorderWidth: StyleParamType.Number;
  blogPostTextPadding: StyleParamType.Number;
  blogPostTitleFont: StyleParamType.Font;
  blogPostTitleFontSize: StyleParamType.Number;
  blogPostTitleColor: StyleParamType.Color;
  blogPostExcerptFont: StyleParamType.Font;
  blogPostExcerptFontSize: StyleParamType.Number;
  blogPostExcerptColor: StyleParamType.Color;
  blogPostHoverColor: StyleParamType.Color;
  emptyStateTitleFont: StyleParamType.Font;
  emptyStateTitleFontSize: StyleParamType.Number;
  emptyStateTitleColor: StyleParamType.Color;
  emptyStateDescriptionFont: StyleParamType.Font;
  emptyStateDescriptionFontSize: StyleParamType.Number;
  emptyStateDescriptionColor: StyleParamType.Color;
  buttonCornerRadius: StyleParamType.Number;
  blogPostCornerRadius: StyleParamType.Number;
};

export default createStylesParams<StylesParams>({
  wrapperBackgroundColor: {
    key: 'box-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  wrapperBorderColor: {
    key: 'border-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  wrapperBorderWidth: {
    key: 'border-width-2',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  spaceFromTop: {
    key: 'space-from-top',
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
    inheritDesktop: false,
  },
  spaceOverviewInformation: {
    key: 'space-overview-information',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  spaceBetweenSections: {
    key: 'space-between-sections',
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
    inheritDesktop: false,
  },
  titleFont: {
    key: 'text-title-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 26 }),
  },
  titleColor: {
    key: 'text-color-title',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionTitleFont: {
    key: 'section-title-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-S', { size: 20 }),
  },
  sectionTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  sectionTitleColor: {
    key: 'section-title-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionDescriptionFont: {
    key: 'section-description-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 15 }),
  },
  sectionDescriptionFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  sectionDescriptionColor: {
    key: 'section-description-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonFont: {
    key: 'button-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  buttonFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  buttonBgColor: {
    key: 'button-opacity-and-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  textPrimaryColor: {
    key: 'text-color-primary',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  dividerColor: {
    key: 'dividers-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  dividerWidth: {
    key: 'dividers-width',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  fieldLabelFont: {
    key: 'field-label-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  fieldLabelFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  fieldLabelColor: {
    key: 'field-label-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  fieldPlaceholderFont: {
    key: 'field-placeholder-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  fieldPlaceholderFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  fieldPlaceholderColor: {
    key: 'field-placeholder-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  fieldTextFont: {
    key: 'field-text-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  fieldTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  fieldTextColor: {
    key: 'field-text-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  badgeTitleFont: {
    key: 'badge-title-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      style: { bold: true, italic: false, underline: false },
    }),
  },
  badgeTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
  badgeTitleColor: {
    key: 'badge-title-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  badgeFont: {
    key: 'badge-description-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  badgeFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
  badgeColor: {
    key: 'badge-description-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  blogPostCardWidth: {
    key: 'post-list-card-width',
    type: StyleParamType.Number,
    getDefaultValue: () => 288,
  },
  blogPostCardHeight: {
    key: 'post-list-card-height',
    type: StyleParamType.Number,
    getDefaultValue: () => 250,
  },
  blogPostCardMargin: {
    key: 'post-list-card-margin',
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  blogPostCardBackgroundColor: {
    key: 'post-background-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  blogPostCardBorderColor: {
    key: 'post-border-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  blogPostCardBorderWidth: {
    key: 'post-border-width',
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 0 : 1),
  },
  blogPostTextPadding: {
    key: 'post-list-text-padding',
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  blogPostTitleFont: {
    key: 'text-post-title-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', { size: 26 }),
  },
  blogPostTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  blogPostTitleColor: {
    key: 'text-post-title-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  blogPostExcerptFont: {
    key: 'text-post-description-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 15 }),
  },
  blogPostExcerptFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 15,
  },
  blogPostExcerptColor: {
    key: 'text-post-description-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  blogPostHoverColor: {
    key: 'text-post-hover-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  emptyStateTitleFont: {
    key: 'empty-state-title-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 20 }),
  },
  emptyStateTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  emptyStateTitleColor: {
    key: 'empty-state-title-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  emptyStateDescriptionFont: {
    key: 'empty-state-description-font',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 15 }),
  },
  emptyStateDescriptionFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 15,
  },
  emptyStateDescriptionColor: {
    key: 'empty-state-description-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  buttonCornerRadius: {
    key: 'button-corner-radius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  blogPostCornerRadius: {
    key: 'blog-post-corner-radius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});
