import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { classes, st } from './Section.st.css';

interface SectionProps {
  className?: string;
}

export const Section: FC<SectionProps> = ({ className, children }) => {
  const { isMobile } = useEnvironment();
  return (
    <div className={st(classes.root, { mobile: isMobile }, className)}>
      {children}
    </div>
  );
};
