import { useSettings } from '@wix/tpa-settings/react';

import { useMember } from '../../../../contexts/widgets';
import settingsParams from '../../settingsParams';

export const useBadgesRenderer = () => {
  const settings = useSettings();
  const { badges } = useMember();

  const showBadgesSection = settings.get(settingsParams.showBadgesSection);
  const hasBadges = badges.length > 0;

  return { shouldRenderBadges: showBadgesSection && hasBadges };
};
