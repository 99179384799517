import React, { FC } from 'react';
import { Text, TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { useMemberHandlers } from '../../../../contexts/widgets';
import settingsParams from '../../settingsParams';
import { classes, st } from './EmptyState.st.css';

interface EmptyStateProps {
  isBlocked: boolean;
}

export const EmptyState: FC<EmptyStateProps> = ({ isBlocked }) => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { navigateToMyAccount } = useMemberHandlers();

  const title = isBlocked
    ? t('no-about.blocked-member.content')
    : settings.get(settingsParams.emptyStateTitle);

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <div className={classes.content}>
        <Text tagName="div" className={classes.title}>
          {title}
        </Text>
        {isBlocked ? (
          <TextButton
            className={classes.myAccountLink}
            priority={TextButtonPriority.primary}
            onClick={() => navigateToMyAccount()}
          >
            {t('no-about.blocked-member.my-account-link')}
          </TextButton>
        ) : (
          <Text tagName="div" className={classes.description}>
            {settings.get(settingsParams.emptyStateText)}
          </Text>
        )}
      </div>
    </div>
  );
};
