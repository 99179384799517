import React, { FC } from 'react';
import {
  Toast as TPAToast,
  ToastPlacement as TOAST_PLACEMENT,
} from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { useUI, useUIHandlers } from '../../../../../contexts/widgets';
import { getToastContent } from './getToastContent';
import { classes, st } from './Toast.st.css';
import { DataHook } from '../../../../../types';

export const Toast: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { toast } = useUI();
  const { hideToast } = useUIHandlers();

  const { toastContent, toastSkin } = getToastContent(toast.type, t);

  return (
    <TPAToast
      className={st(classes.toast, {
        mobile: isMobile,
        displayNone: !toast.isVisible,
      })}
      skin={toastSkin}
      placement={
        isMobile ? TOAST_PLACEMENT.bottomFullWidth : TOAST_PLACEMENT.inline
      }
      children={toastContent}
      isShown={toast.isVisible}
      shouldShowCloseButton={true}
      onClose={() => hideToast()}
      data-hook={DataHook.Toast}
    />
  );
};
