import React, { FC } from 'react';

import { BlogPost, PostImageFill } from '../../../../../../../types';
import { useCover, usePostImage } from './hooks';
import { classes, st, vars } from './Cover.st.css';

type PostCoverProps = Pick<BlogPost, 'coverMedia'>;

export const Cover: FC<PostCoverProps> = ({ coverMedia }) => {
  const { coverUrl, coverHeight } = useCover(coverMedia);
  const { postImageFill } = usePostImage();

  return (
    <div
      className={st(classes.root, {
        filled: postImageFill === PostImageFill.Fill,
        cropped: postImageFill === PostImageFill.Crop,
      })}
      style={{ [vars.coverHeight]: `${coverHeight}px` }}
    >
      <img className={classes.img} src={coverUrl} alt="" />
    </div>
  );
};
