import React, { FC } from 'react';
import {
  Button,
  ButtonPriority as PRIORITY,
  ThreeDotsLoader,
} from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { BlogPost, DataHook } from '../../../../../types';
import { useBlogPostsHandlers } from '../../../../../contexts/widgets';
import Post from './Post';
import { classes, st } from './PostList.st.css';

interface PostListProps {
  posts: BlogPost[];
  paging: { count: number; offset: number; total: number };
  arePostsLoading: boolean;
}

export const PostList: FC<PostListProps> = ({
  posts,
  paging,
  arePostsLoading,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { fetchPosts } = useBlogPostsHandlers();
  const shouldShowLoadMoreButton = paging.total > paging.count;

  return (
    <div className={classes.root}>
      <div className={classes.posts}>
        {posts.map((post) => (
          <Post key={post.id} {...post} />
        ))}
      </div>
      {shouldShowLoadMoreButton && (
        <div className={classes.loadMoreButtonContainer}>
          {arePostsLoading ? (
            <ThreeDotsLoader
              data-hook={DataHook.PostsLoader}
              className={classes.loader}
            />
          ) : (
            <Button
              type="button"
              data-hook={DataHook.LoadMorePostsButton}
              className={st(classes.loadMoreButton, { mobile: isMobile })}
              priority={PRIORITY.secondary}
              upgrade={true}
              onClick={() => fetchPosts()}
            >
              {t('recent-posts.load-more')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
