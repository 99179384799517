import { MemberAbout } from '../../../../../types';
import { useMember, useCanEditMember } from '../../../../../contexts/widgets';

export const isAboutFilled = (about: MemberAbout | null) => {
  return (
    (about?.blocks?.length ?? 0) > 1 ||
    (about?.blocks?.[0].text.length ?? 0) > 0
  );
};

export const useAboutRendered = () => {
  const { about } = useMember();
  const { canEditMember } = useCanEditMember();

  return { shouldRenderAbout: canEditMember || isAboutFilled(about) };
};
