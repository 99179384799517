import { useSettings } from '@wix/tpa-settings/react';

import { useAboutRendered } from './About/hooks';
import { useSite } from '../../../../contexts/widgets';
import settingsParams from '../../settingsParams';

export { useAboutRendered } from './About/hooks';

const defaultNumbersVisibility = {
  showCommentsStats: false,
  showLikesReceivedStats: false,
  showTopCommentsStats: false,
};

export const useNumbersVisibility = () => {
  const settings = useSettings();
  const { isForumInstalled } = useSite();

  const showCommentsStats = settings.get(settingsParams.showCommentsStats);
  const showLikesReceivedStats = settings.get(
    settingsParams.showLikesReceivedStats,
  );
  const showTopCommentsStats = settings.get(
    settingsParams.showTopCommentsStats,
  );

  return isForumInstalled
    ? { showCommentsStats, showLikesReceivedStats, showTopCommentsStats }
    : defaultNumbersVisibility;
};

export const useDescriptionRenderer = () => {
  const settings = useSettings();
  const { shouldRenderAbout } = useAboutRendered();
  const numbersVisibility = useNumbersVisibility();
  const showAboutSection = settings.get(settingsParams.showAboutSection);

  if (!showAboutSection) {
    return { shouldRenderDescription: false };
  }

  const shouldShowNumbers = Object.values(numbersVisibility).some(Boolean);

  return {
    shouldRenderDescription: shouldShowNumbers || shouldRenderAbout,
  };
};
